import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { User } from '../interfaces/users.model';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import icPrint from '@iconify/icons-ic/twotone-print';
import icDownload from '@iconify/icons-ic/twotone-cloud-download';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSecurity from '@iconify/icons-ic/twotone-security';
import icEmail from '@iconify/icons-ic/twotone-email';
import icEditLocation from '@iconify/icons-ic/twotone-edit-location';
import { MatSnackBar } from '@angular/material';
import icHorizontalSplit from '@iconify/icons-ic/twotone-horizontal-split';
import { CommonService } from 'src/app/services/core/common.service';
import { SocketService } from 'src/app/services/core/socket.service';
import { environment } from 'src/environments/environment';
import { HearingsCreateUpdateComponent } from '../hearings-create-update/hearings-create-update.component';

@Component({
  selector: 'vex-hearing-complete',
  templateUrl: './hearing-complete.component.html',
  styleUrls: ['./hearing-complete.component.scss']
})
export class HearingCompleteComponent implements OnInit {
  private socketObject;

  public courtCasesList = [];
  public courtsList = [];

  form: FormGroup;
  mode: 'create' | 'update' = 'create';

  icMoreVert = icMoreVert;
  icClose = icClose;

  icHorizontalSplit = icHorizontalSplit;
  icPrint = icPrint;
  icDownload = icDownload;
  icDelete = icDelete;
  icEmail = icEmail;
  icSecurity = icSecurity;

  icPerson = icPerson;
  icEditLocation = icEditLocation;

  constructor(@Inject(MAT_DIALOG_DATA) public userDetails: any,
    private dialogRef: MatDialogRef<HearingCompleteComponent>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private commonServices: CommonService,
    private socketService: SocketService,
    private dialog: MatDialog,) {

    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  ngOnInit() {

    if (this.userDetails) {
      this.mode = 'update';
    } else {
      this.userDetails = {} as User;
    }

    this.form = this.fb.group({
      _id: this.userDetails._id || '',
      completedRemarks: [this.userDetails.completedRemarks ? this.userDetails.completedRemarks : '', Validators.required],
      isAdd: ''
    });
  }

  save() {
    this.createCustomer();
  }

  createCustomer() {
    const customer = this.form.value;
    if (this.form.invalid) {
      this.snackBar.open('Please provide all required information.', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
      return;
    }
    this.showAddHearingDialog()
    this.dialogRef.close(customer);
  }


  showAddHearingDialog() {
    if (this.form.value.isAdd == true) {
      this.dialog.open(HearingsCreateUpdateComponent, {
        panelClass: 'hearings-dialog-container',
        data: {
          courtCase: this.userDetails.courtCase
        },
        disableClose: true
      }).afterClosed().subscribe((hearingDetails) => {
        if (hearingDetails) {
          if (this.socketService.isSocketConnected()) {
            let tmpObj = this;
            this.socketObject.emit(environment.socketEvents.createHearing, hearingDetails, function (socketResponse) {
              if (socketResponse.status == 'OK') {
                tmpObj.snackBar.open('Successfully Created Hearing.', 'Okay', {
                  duration: 4000
                });
                //tmpObj.refreshData(tmpObj);
              } else {
                tmpObj.snackBar.open(socketResponse.message, 'Okay', {
                  duration: 4000
                });
              }
            });
          }
        }
      });
    }
  }

}
