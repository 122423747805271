import { Injectable } from '@angular/core';
import { HttpClient, HttpParams,HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common'
import { Router, NavigationEnd } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public eCurl = environment.serviceConfiguration.url;

  constructor(private router: Router, private location: Location,public http: HttpClient) { }

  postecourt(urlT: any, data: any) {
    const updateUrl = this.eCurl + urlT;
  console.log(updateUrl)
   
    return this.http.post(updateUrl, data);
  }
  
  postyear(urlT: any){
    const updateUrl = this.eCurl + urlT;
  let obj={
  
  }
   
    return this.http.post(updateUrl,obj);
  
  }
}
